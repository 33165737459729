<ion-button
  expand="block"
  [class.uppercase]="uppercase"
  [class.bold]="bold"
  [class]="size + ' ' + color"
  [class.circle]="shape === 'circle'"
  (click)="buttonClick($event)"
  [disabled]="disabled || isLoading"
  [style]="{ minWidth: minWidthPx }"
  [expand]="expand"
  [class.desktop]="platform.is('desktop')"
  [shape]="'round'">
  @if (isLoading) {
    <div class="spinner">
      <ion-spinner name="crescent" [class.white-theme]="color === 'white'"></ion-spinner>
    </div>
  }
  <div [class.hide-content]="isLoading">
    @if (icon && iconPlacement === 'start') {
      <img [src]="icon" alt="" class="icon start" [class.small]="size === 'small'" />
    }

    {{ label }}

    @if (icon && iconPlacement === 'end') {
      <img [src]="icon" alt="" class="icon end" [class.small]="size === 'small'" />
    }
  </div>
</ion-button>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonButton, IonSpinner, Platform } from '@ionic/angular/standalone';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [IonSpinner, IonButton],
})
export class ButtonComponent {
  @Input() icon?: string;
  @Input() iconPlacement: 'start' | 'end' = 'start';

  @Input() isLoading: boolean = false;

  @Input() label: string = '';
  @Input() size: 'xxsmall' | 'xsmall' | 'small' | 'normal' = 'normal';
  @Input() expand: 'block' | 'default' = 'block';
  @Input() shape: 'default' | 'circle' = 'default';
  @Input() color: 'violet' | 'white' | 'orange' | 'green' | 'blue' = 'violet';

  @Input() minWidthPx: string = '';

  @Input() disabled: boolean = false;
  @Input() uppercase: boolean = false;
  @Input() bold: boolean = false;

  @Output() clicked = new EventEmitter<void>();

  constructor(public platform: Platform) {}

  buttonClick(event: MouseEvent): void {
    this.clicked.emit();
    event.preventDefault();
  }
}

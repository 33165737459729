export enum PageViewEvents {
  // Root pages
  Home = 'Home',
  MyLibrary = 'MyLibrary',
  Menu = 'Menu',
  Language = 'AppLanguage',
  LibraryCode = 'LibraryCode',
  DeleteAccount = 'DeleteAccount',
  HowToUse = 'HowToUse',
  BookError = 'BookError',
  Shelf = 'Shelf',

  // Authentication screens - Should match the AuthenticationSteps interface
  AuthenticationEmailStep = 'AuthenticationEmailStep',
  AuthenticationLoginStep = 'AuthenticationLoginStep',
  AuthenticationCreateAccountStep = 'AuthenticationCreateAccountStep',
  AuthenticationAccountCreatedStep = 'AuthenticationAccountCreatedStep',
  AuthenticationForgotPasswordStep = 'AuthenticationForgotPasswordStep',
  AuthenticationForgotPasswordConfirmStep = 'AuthenticationForgotPasswordConfirmStep',
  AuthenticationResetPasswordStep = 'AuthenticationResetPasswordStep',
  AuthenticationResetPasswordConfirmStep = 'AuthenticationResetPasswordConfirmStep',
  AuthenticationEmailValidatedStep = 'AuthenticationEmailValidatedStep',
  AuthenticationEmailNotValidatedStep = 'AuthenticationEmailNotValidatedStep',
  AuthenticationLegacyUserWelcomeStep = 'AuthenticationLegacyUserWelcomeStep',
  AuthenticationLegacyUserOnboardingStep = 'AuthenticationLegacyUserOnboardingStep',

  // Profile Screens
  Profile = 'ProfileDashboard',
  ProfileSelect = 'ProfileSelect',
  ProfileDelete = 'ProfileDelete',
  ProfileEdit = 'ProfileEdit',
  // Profile creation page (desktop & mobile)
  ProfileCreate = 'ProfileCreate',
  // Profile creation screens (mobile only) - Should match the ProfileCreationSteps interface
  ProfileCreateNameStep = 'ProfileCreateNameStep',
  ProfileCreateAvatarStep = 'ProfileCreateAvatarStep',
  ProfileCreateRangeStep = 'ProfileCreateRangeStep',
  ProfileCreateGenderStep = 'ProfileCreateGenderStep',
  ProfileCreateGoalStep = 'ProfileCreateGoalStep',

  // Reader Screens
  BookDetails = 'BookDetails',
  BookComplete = 'BookComplete',
  BookReader = 'BookReader',
}

export enum ModalViewEvents {
  // Authentication modals is tracked with PageViewEvents events
  ActivityModal = 'ActivityModal',
  CreateAccountOrLoginPromptModal = 'CreateAccountOrLoginPromptModal',
  CreateAccountOrReadBookPromptModal = 'CreateAccountOrReadBookPromptModal',
  DeleteAccountModal = 'DeleteAccountModal',
  LanguageSelectionModal = 'LanguageSelectionModal',
  ContentLanguageSelectionModal = 'ContentLanguageSelectionModal',
  ContentGradeSelectionModal = 'ContentGradeSelectionModal',
  LogoutConfirmationModal = 'LogoutConfirmationModal',
  ProfileOnboardingModal = 'ProfileOnboardingModal',
  MoyaOnboardingModal = 'MoyaOnboardingModal',
  ShareBookModal = 'ShareBookModal',
  LeaveProjectModal = 'LeaveProjectModal',
  SwitchOrAddProfile = 'SwitchOrAddProfileModal',
  SelectCategoryModal = 'SelectCategoryModal',
}

export enum ClickSourceType {
  FeatureShelf = 'FeatureShelf',
  MyLibray = 'MyLibray',
  Popular = 'Popular',
  New = 'New',
  ShelfDetails = 'ShelfDetails',
}

// TODO
export enum UserActionEvents {}

export enum AnalyticsEventType {
  InScreen = 'inScreen',
  BookDetails = 'BookDetails',
  BookDescription = 'BookDescription',
  BookCredits = 'BookCredits',
  BookShared = 'BookShared',
  BookFavorite = 'BookFavorite',
  BookUnfavorite = 'BookUnfavorite',
  BookLoading = 'BookLoading',
  BookLoaded = 'BookLoaded',
  BookRead = 'BookRead',
  BookFinished = 'BookFinished',
  BookLiked = 'BookLiked',
  BookUnliked = 'BookUnliked',
  ActivityOpened = 'ActivityOpened',
  ActivityCompleted = 'ActivityCompleted',
  ActivityClosed = 'ActivityClosed',
  Login = 'Login',
  Registration = 'Registration',
  CreateUserProfile = 'CreateUserProfile',
  UpdateUserProfile = 'UpdateUserProfile',
  DeleteUserProfile = 'DeleteUserProfile',
  SelectedUserProfile = 'SelectedUserProfile',
  SelectedBookCategory = 'SelectedBookCategory',
}

export type EventType =
  | 'inScreen'
  | 'BookDetails'
  | 'BookDescription'
  | 'BookCredits'
  | 'BookShared'
  | 'BookFavorite'
  | 'BookUnfavorite'
  | 'BookLoading'
  | 'BookLoaded'
  | 'BookRead'
  | 'BookFinished'
  | 'BookLiked'
  | 'BookUnliked'
  | 'ActivityOpened'
  | 'ActivityCompleted'
  | 'ActivityClosed'
  | 'Login'
  | 'Registration'
  | 'CreateUserProfile'
  | 'UpdateUserProfile'
  | 'DeleteUserProfile'
  | 'SelectedUserProfile';

export type AnalyticsEvents = UserActionEvents | PageViewEvents | ModalViewEvents;

export type EventAttributesMapper = {
  inScreen: InScreenAttributes;
  BookClick: BookClickAttributes;
  BookDetails: BasicBookAttributes;
  BookDescription: BookDescriptionAttributes;
  BookCredits: BookCreditsAttributes;
  BookShared: BookSharedAttributes;
  BookFavorite: BasicBookAttributes;
  BookUnfavorite: BasicBookAttributes;
  BookLoading: BasicBookAttributes;
  BookLoaded: BasicBookAttributes;
  BookRead: BookReadAttributes;
  BookFinished: BookFinishedAttributes;
  BookLiked: BasicBookAttributes;
  BookUnliked: BasicBookAttributes;
  ActivityOpened: BasicActivityAttributes;
  ActivityCompleted: BasicActivityAttributes;
  ActivityClosed: BasicActivityAttributes;

  // Events with no custom attributes
  Registration: BasicUserAttributes;
  Login: BasicUserAttributes;
  CreateUserProfile: null;
  UpdateUserProfile: null;
  DeleteUserProfile: null;
  SelectedUserProfile: null;
};

type BookClickAttributes = {
  screenName: PageViewEvents | ModalViewEvents;
  sourceType: ClickSourceType;
  bookId: string;
  categoryId?: number;
  shelfId?: number;
  featuredId?: number;
};

type InScreenAttributes = {
  screenName: PageViewEvents | ModalViewEvents;
};

type BasicBookAttributes = {
  bookId: string;
};

type BookDescriptionAttributes = {
  bookId: string;
  action: 'open' | 'close';
};

type BookCreditsAttributes = {
  bookId: string;
  action: 'open' | 'close';
};

type BookSharedAttributes = {
  bookId: string;
  option: 'link' | 'facebook' | 'whatsapp' | 'email';
};

type BasicActivityAttributes = {
  bookId: string;
  activityId: number;
};

type BookFinishedAttributes = {
  bookId: string;
  earnedPoints: number;
  totalEarnedPoints: number;
  totalFinishedBooks: number;
};

type BookReadAttributes = {
  bookId: string;

  // Books API
  bookSpineSize: number; // Number of spine elements (5)
  bookCurrentlyReadingSpineElement: number; // Current spine element (2)
  // spineElementSizeInChars: number;
  screenWordCount: number; // Count worlds being displayed right now

  amountOfScreensForSpineElement: number;
  currentScreenNumberInSpineElement: number;

  screenFirstSentence: string; // Get form the actual content displayed on the screen
  screenLastSentence: string;

  readingDirection: 'next' | 'previous';
  screenTextSizeInChars: number; // If we implement screens (or chunks) screen_text_size would be diferent of spine_element_size_in_chars.
};

type BasicUserAttributes = {
  authProvider: 'worldreader' | 'google' | 'facebook';
};
